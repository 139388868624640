@import url('146.css');
@keyframes rotate {
	100% {
		transform: rotate(360deg);
	}
}

fieldset,
form {
	border: 0 !important;
	outline: 0 !important;
	padding: 0;
	margin: 0;
}
*,
:before,
:after {
	box-sizing: border-box;
}
:root {
	--background: #fff;
	--text: #333;
	--bginfo: #f6f6f6;
	--accent: #ff91e3;
	--border: #ccc;
	--gutter: 1em;
}
.darkmode {
	--background: #333;
	--bginfo: #444;
	--border: #666;
	--gallery: #777;
	--text: #f6f6f6;
	--accent: #6ee9ff;
}
.gallery {
	background-color: var(--gallery) !important;
	border-color: var(--accent) !important;
	z-index: 2 !important;
}
html {
	font-family: sans-serif;
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}
body {
	scroll-behavior: smooth;
	margin: 0;
	padding: 0;
	color: var(--text);
	background-color: var(--background);
	font-family: 'Rubik', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	transition: color 500ms ease-in-out, background-color 500ms ease-in-out;
}
a {
	text-decoration: none;
	border: 0;
	outline: 0;
	margin: 0;
	padding: 0;
	color: var(--color);
}
a:hover {
	color: var(--accent);
}

input,
button {
	background-color: var(--background);
	color: var(--text);
	border: 0;
}

header {
	color: var(--text);
	cursor: default;
	background-color: var(--background);
	overflow: hidden;
	border: 1px solid #333;
	box-shadow: 0em 0em 1rem var(--text);
	z-index: 999;
	position: fixed;
	border-radius: 0.5em;
	bottom: 1em;
	right: 1em;
	text-align: center;
}

header .info {
	padding: 2em var(--gutter);
}
header .info > h3 {
	padding: 0;
	margin: 0;
}
header .intro,
header .help {
	padding: 0em;
}
header .help > p {
	font-size: 0.825em;
}
header .sub {
	background-color: var(--text);
	width: 100%;
	padding: 1em;
}
header .sub input {
	font-family: 'Rubik', sans-serif;
	text-transform: lowercase;
	width: 100%;
	margin: 0;
	padding: 0.25em;
	font-size: 1.25rem;
	border: 0;
	outline: none;
	text-align: left;
	background-color: var(--text);
	color: var(--background);
	outline: none;
}
header .settings {
	width: 100%;
	display: flex;
	justify-content: space-between;
	padding: var(--gutter);
}
header .settings .icons {
	color: var(--text);
	z-index: 999;
	margin-left: 0.5em;
	cursor: pointer !important;
}
header .settings .icons:first-child {
	margin-left: 0;
}
header form {
	padding: 0;
	margin: 0;
}

@media only screen and (max-width: 500px) {
	header {
		left: 1em;
	}
}
@media only screen and (min-width: 501px) {
	header {
		left: auto;
	}
}

.menucontainer {
	position: fixed;
	bottom: 1em;
	right: 1em;
	z-index: 3;
	opacity: 0.5;
	transition: opacity 0.5s;
}
.menucontainer:hover {
	opacity: 1;
	transition: opacity 0.5s;
}
.menucontainer .active {
	border: 0;
	background-color: var(--text);
	color: var(--background);
	transform: scale(80%) translateY(-0.25em);
}
.menu {
	position: relative;
	text-align: center;
	padding-top: 0.4em;
	font-size: 2em;
	z-index: 4;
	width: 2em;
	height: 2em;
	border-radius: 50%;
	border: 1px solid var(--text);
	cursor: pointer;
	background-color: var(--background);
}
.menu::after {
	content: 'M';
}
.loading::before {
	content: '';
	position: absolute;
	top: -10%;
	left: -10%;
	background-image: conic-gradient(
		rgba(255, 255, 255, 0) 0deg,
		var(--accent) 270deg
	);
	height: 120%;
	width: 120%;
	border-radius: 50%;
	animation: rotate 2s infinite linear;
}

.loading::after {
	font-size: 0.3em;
	content: 'Loading';
	padding-top: 2.5em;
	background-color: var(--background);
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0%;
	top: 0%;
	border-radius: 50%;
	border: 1px solid var(--text);
}

.my-masonry-grid {
	display: -webkit-box; /* Not needed if autoprefixing */
	display: -ms-flexbox; /* Not needed if autoprefixing */
	display: flex;
	margin-left: calc(0em - var(--gutter)); /* gutter size offset */
	width: auto;
}
.my-masonry-grid_column {
	padding-left: var(--gutter); /* gutter size */
	background-clip: padding-box;
}
.my-masonry-grid_column > div {
	margin-bottom: var(--gutter);
}
.repo-container {
	margin: var(--gutter);
	margin-bottom: 3rem;
}
.lastpost-container {
	position: relative;
	width: 100%;
}
.postfull {
	position: relative;
	background-position: center center;
	background-size: contain;
	background-repeat: no-repeat;
	width: 100%;
	padding: 0;
	border-radius: 0.25em;
	overflow: hidden;
	border: 1px solid var(--border);
	background-color: var(--bginfo);
	z-index: 1;
}
.postfull img {
	background-position: center center;
	background-size: contain;
	background-repeat: no-repeat;
	display: block;
	width: 100%;
	border: 0;
	white-space: pre;
	border-bottom: 1px solid var(--border);
}
.postfull .nsfw {
	background-position: center center;
	background-size: contain;
	background-repeat: no-repeat;
	display: flex;
	align-items: center;
	justify-content: center;
	left: 0;
	right: 0;
	width: 100%;
	color: var(--text);
	content: 'NSFW!';
	font-size: 3em;
	border-bottom: 1px solid var(--border);
}
.postfull .info {
	position: relative;
	width: 100%;
	display: flex;
	justify-content: space-between;
	column-gap: 1em;
	align-items: center;
	padding: 0.5em 1em 0.5em;
	margin: 0;
}
.postfull .info .icons {
	cursor: pointer !important;
	opacity: 0.2;
	color: var(--text);
	transition: opacity 0.5s;
	margin-right: 0.25em;
}
.postfull .info .icons:hover {
	opacity: 1;
	transition: opacity 0.5s;
}
.modal {
	width: 50%;
	margin: 4em auto 4em;
	text-align: center;
}
.modal p {
	font-size: 1.5rem;
}
.bold {
	font-weight: bold;
}
.small {
	font-size: 0.75em;
	font-weight: normal;
}
.blackout {
	position: absolute;
	display: none;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background-color: var(--background);
	opacity: 0;
	z-index: 2;
	transition: opacity 0.5s;
}
.on {
	display: block;
	opacity: 0.8;
	transition: opacity 0.5s;
}
