@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;500&display=swap');
.logo a {
	padding: 0;
	margin: 0;
	color: var(--text);
	font-size: min(2em, 24px);
	background-color: var(--background);
}

.logo h1 {
	font-weight: 500;
	letter-spacing: calc(2px * 2vmin);
	margin: 0;
	padding: 0;
	opacity: 1;
	transition: opacity 2s;
}
.logo h1 .small {
	font-weight: 150;
	font-size: 0.5em;
}
.logo h2.slogan {
	font: 1rem;
	color: var(--text);
	padding: 0;
	margin-top: -0.7em;
	letter-spacing: 0.065em;
	text-transform: uppercase;
	font-size: 0.24em;
	font-weight: 300;
}

.logo h1 .reverse {
	display: inline-block;
	position: relative;
	-moz-transform: scale(-1, 1);
	-webkit-transform: scale(-1, 1);
	-o-transform: scale(-1, 1);
	-ms-transform: scale(-1, 1);
	transform: scale(-1, 1);
}

.logo h1 .rotate {
	color: var(--accent);
	visibility: visible;
	font-size: 1em;
	position: relative;
	display: inline-block;
	margin: 0 0.072em 0 -0.072em;
	padding: 0;
	transform: rotate(-34.5deg);
}
